<template>
  <section class="products">
    <v-back-menu class="fix">
      <v-back-btn :to="`/`"> </v-back-btn>
      <h2 class="products__title" v-if="CATEGORY_PRODUCTS.length !== 0">
        {{ CATEGORY_PRODUCTS[0].category_name }}
      </h2>
    </v-back-menu>
    <v-category-list style="padding: 30px 0px"></v-category-list>
    <transition
      appear
      :css="false"
      @before-enter="beforeEnter"
      @enter="enter"
      v-show="ADMIN_LAYOUT?.admin_emit && popup_data.options.visible"
    >
      <v-popup
        v-show="ADMIN_LAYOUT?.admin_emit && popup_data.options.visible"
        @adminSave="addNewProduct"
        @adminSaveChanges="saveProductChanges"
        :data="popup_data"
      >
        <div class="popup__container">
          <input
            type="file"
            @change="onFileChange($event)"
            placeholder="Картинка"
            class="popup__input-file"
          />
          <input
            type="text"
            placeholder="Название категории"
            v-model.trim="placeholder_product_form.category_name"
            class="popup__input-text"
          />
          <div class="popup__wrapper">
            <!-- <v-btn :icon="true" @click="changeCategoryPosition(placeholder_product_form)">
            <svg
              width="21"
              height="12"
              viewBox="0 0 21 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5.67066 1L1 6M1 6L5.67066 11M1 6H21" stroke="#2C2C2C" />
            </svg>
          </v-btn>
          <v-btn :icon="true" @click="changeCategoryPosition(placeholder_product_form)">
            <svg
              width="21"
              height="12"
              viewBox="0 0 21 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.3293 1L20 6M20 6L15.3293 11M20 6H4.99189e-07"
                stroke="#2C2C2C"
              />
            </svg>
          </v-btn> -->
          </div>

          <input
            type="text"
            placeholder="ID категории"
            :data="0"
            v-model.trim.number="placeholder_product_form.category_id"
            class="popup__input-text"
          />
          <input
            type="color"
            @input="onColorChange($event, 'category_color')"
            placeholder=""
            class="popup__input-colorpicker"
          />
          <input
            type="color"
            @input="onColorChange($event, 'category_text_color')"
            placeholder=""
            class="popup__input-colorpicker"
          />
          <label for="hidden" class="popup__input-label">
            Скрыть категорию
            <input
              type="checkbox"
              name="hidden"
              id="hidden"
              @input="
                $emit(
                  'update:placeholder_product_form.hidden',
                  $event.target.value
                )
              "
              v-model="placeholder_product_form.hidden"
              class="popup__input-checkbox"
            />
          </label>
        </div>
      </v-popup>
    </transition>
    <div class="products__list">
      <!-- <article class="card" @click="getProductInfo">
        <div class="card__head">
          <picture class="card__image">
            <img
              :src="`${this.$store.state.appURL}/products/${placeholder_product_form.name}.jpg`"
              alt=""
            />
          </picture>
        </div>
        <div class="card__body">
          <h3 class="card__title">{{ placeholder_product_form.name }}</h3>
          <p class="card__description">
            {{ placeholder_product_form.description }}
          </p>
        </div>
        <div class="card__footer">
          <p class="card__price">
            {{ placeholder_product_form.total_price }} ₽
          </p>
          <button class="card__button buy-btn">
            <v-icon name="plus-icon" class="plus-icon"></v-icon>
            <img
              class="buy-support buy-animate-icon"
              src="@/assets/media/icons/success-icon.svg"
              alt=""
            />
          </button>
        </div>
      </article> -->
      <v-product-item
        v-for="product in CATEGORY_PRODUCTS.filter(
          (item) => item.name !== 'Сяке Хот за 230р'
        )"
        :key="product.id"
        :product_data="product"
      ></v-product-item>
    </div>
    <div class="products__actions"></div>

    <!-- <div class="products__view"></div> -->
    <transition name="popup" mode="out-in">
      <v-product-popup v-if="PRODUCT_INFO?.name"></v-product-popup>
    </transition>
  </section>
</template>

<script>
import vProductItem from "@/components/products/v-product-item";
import vCategoryList from "@/components/categories/v-category-list";
import { mapGetters, mapActions } from "vuex";
import gsap from "gsap";
export default {
  name: "v-product-list",
  components: {
    vProductItem,
    vCategoryList,
  },
  data() {
    return {
      placeholder_product_form: {
        id: 114,
        name: "блюдо заполнитель",
        price: 345,
        fixed_price: 345,
        total_price: 345,
        weight: 240,
        calory: 320,
        ingredients: [],
        category_id: 2,
        count: 1,
        favorites: false,
        description: "Угорь, огурец, сливочный сыр, рис, кунжут",
        category_name: "Холодные роллы",
        modifications: [],
      },
      popup_data: {
        options: {
          visible: false,
          controll: "",
          require_auth: true,
        },
        title: "Добавить блюдо",
      },
    };
  },
  computed: {
    ...mapGetters([
      "CATEGORY_PRODUCTS",
      "PRODUCT_INFO",
      "ADMIN_LAYOUT",
      "IS_AUTH",
    ]),
  },
  methods: {
    ...mapActions([
      "GET_CATEGORY_PRODUCTS_FROM_API",
      "GET_CATEGORIES_FROM_API",
      "SET_SAVED_FAVORITES",
    ]),
    addNewProduct() {},
    saveProductChanges() {},
    async reRenderCards() {
      await this.GET_CATEGORY_PRODUCTS_FROM_API(
        localStorage.getItem("categoryID")
      );
      this.SET_SAVED_FAVORITES(localStorage.getItem("SAVED_FAVORITES"));
    },
  },
  mounted() {
    this.reRenderCards();
  },
  setup() {
    const beforeEnter = (el) => {
      el;
    };
    const enter = (el) => {
      el;
      gsap.fromTo(
        el,
        0.3,
        {
          opacity: 0,
          transform: "translateX(20px)",
          delay: el.dataset.index * 0.2,
        },
        {
          opacity: 1,
          transform: "translateX(0px)",
          delay: el.dataset.index * 0.2,
        }
      );
    };

    return { beforeEnter, enter };
  },
};
</script>

<style lang="scss" scoped>
.fix {
  margin-right: 20px;
  margin-left: 0px;
}

.products {
  padding-left: 10px;
  &__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    .card {
      @include container;
      min-width: 176px;
      max-width: 176px;
      width: 100%;
      padding: 9px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-right: 20px;
      margin-bottom: 20px;
      cursor: pointer;
      &__head {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-bottom: 10px;
      }
      &__image * {
        max-width: 120px;
        max-height: 100%;
        min-height: 78px;
      }
      &__body {
      }
      &__title {
        @include h3;
      }
      &__description {
        color: $second-black;
        font-size: 12px;
      }
      &__footer {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
      }
      &__price {
        font-weight: 500;
      }
      &__button {
        cursor: pointer;
        display: flex;
        align-items: center;
        svg {
          pointer-events: none;
        }
      }
    }
  }
  &__title {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }
}

.popup-enter-from,
.popup-leave-to {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.popup-enter-active,
.popup-leave-active {
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  -o-transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media (max-width: 1025px) {
  .products__list {
    margin-left: 0px;
    margin-top: 20px;
  }
}
@media (max-width: 416px) {
  .products__list {
    padding-right: 20px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    .card {
      width: 48%;
      max-width: initial;
      min-width: initial;
      margin-right: 0;
    }
  }
}
</style>
