<template>
  <article
    class="card"
    @click="getProductInfo"
    :style="{
      'background-color': product_data?.jaw_color || '#FFDEB4',
    }"
  >
    <slot>
      <div class="card__head">
        <div class="card__image">
          <picture>
            <img :src="product_data.large_image" alt="" />
          </picture>
        </div>

        <v-favorite-btn v-if="!product_data.favorites"></v-favorite-btn>
        <v-favorite-btn-active
          v-if="product_data.favorites == true"
        ></v-favorite-btn-active>
      </div>
      <div class="card__body">
        <h3 class="card__title">{{ product_data.name }}</h3>
        <p class="card__description" v-if="product_data?.sec_description">
          {{ product_data.sec_description }}
        </p>
      </div>
      <div class="card__footer">
        <p class="card__price">
          <span
            v-if="
              product_data?.second_price !== '' && product_data?.second_price
            "
          >
            {{ product_data?.second_price?.split("/")[0] }} ₽
          </span>
          {{ product_data.total_price }} ₽
        </p>

        <button class="card__button buy-btn">
          <v-icon name="plus-icon" class="plus-icon"></v-icon>
          <img
            class="buy-support buy-animate-icon"
            src="@/assets/media/icons/success-icon.svg"
            alt=""
          />
        </button>
      </div>
    </slot>
  </article>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { TimelineMax } from "gsap";

export default {
  name: "v-product-item",
  props: {
    product_data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    ...mapActions([
      "GET_PRODUCT_INFO",
      "ADD_TO_CART",
      "ADD_TO_FAVORITES",
      "CHANGE_PRODUCT",
    ]),
    async getProductInfo(e, data) {
      data = this.product_data;
      if (e.target.classList.contains("buy-btn")) {
        await this.GET_PRODUCT_INFO(this.product_data);
        await this.ADD_TO_CART(this.product_data);

        let icon = e.target.childNodes[0];
        let hiddenIcon = e.target.childNodes[1];

        let tl = new TimelineMax({});

        tl.to(icon, 0.5, {
          rotate: "120deg",
          opacity: 0,
          transform: "scale(0)",
        });

        tl.to(hiddenIcon, 0.5, {
          rotate: "0deg",
          opacity: 1,
          transform: "scale(1)",
          display: "initial",
        });

        tl.play().then(() => {
          setTimeout(() => {
            tl.reverse();
          }, 1000);
        });
        this.$store.state.product = { product: "empty" };
      } else if (
        e.target.parentNode.classList.contains("favorite-btn") ||
        e.target.classList.contains("favorite-icon-path") ||
        e.target.classList.contains("favorite-icon-active") ||
        e.target.classList.contains("favorite-icon")
      ) {
        this.ADD_TO_FAVORITES(data);
      } else {
        this.GET_PRODUCT_INFO(data);
      }
    },
  },
  computed: {
    ...mapGetters(["IS_AUTH"]),
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.product {
  margin-left: 20px;
}

.card {
  @include container;
  min-width: 176px;
  max-width: 176px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  &__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  &__image {
    font-size: 0;
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
  }
  &__image * {
    max-width: inherit;
    height: inherit;
  }
  &__body {
    margin-bottom: -3px;
  }
  &__title {
    @include h3;
  }
  &__description {
    color: $second-black;
    font-size: 12px;
  }
  &__footer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  &__price {
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span {
      font-size: 12px;
      text-decoration: line-through;
    }
  }
  &__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
      pointer-events: none;
    }
  }
}
.buy-animate-icon {
  transform: scale(0);
  display: none;
  min-width: 28px;
  min-height: 28px;
}
@media (max-width: 416px) {
  .card {
    min-width: initial;
    max-width: initial;
    width: 48%;
    margin-right: 0;
    position: relative;
    &__image * {
    }
    .favorite-btn {
      position: absolute;
      top: 12px;
      right: 9px;
    }
  }
}
</style>
